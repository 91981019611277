import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Constants, ToastTypes } from '../../configs/app.config';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private toastOptions: any;
  constructor(
    private toastyService: ToastrService,
    private appConstants: Constants,
    private translateService: TranslateService
  ) {
    this.toastOptions = {
      closeButton: true,
      timeOut: this.appConstants.notificationTimeout,
      positionClass: 'toast-top-right',
      enableHtml: true,
      tapToDismiss: false,
    };
    /*** Uncomment if notify not needed in redirection
		router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                this.toastyService.clearAll();
            }
        });**/
  }

  show(status: number, messages: any) {
    let type: any;
    let msg = '';
    switch (status) {
      case 0:
        type = 'success';
        break;
      case 1:
        type = 'warning';
        break;
      case 2:
        type = 'error';
        break;
      default:
        type = 'default';
    }
    if (_.isArray(messages)) {
      _.forEach(messages, (message, key) => {
        msg += message + '<br>';
      });
    } else {
      msg = this.translateService.instant(messages);
    }

    if (msg !== '') {
      const message = msg;
      const help =
        'For any assistance write to contact@ohmobility.com or call +91-8867037812';
      const title = _.upperFirst(type);
      this.toastyService.error(message, title, this.toastOptions);
      this.toastyService.info(help, 'Info', this.toastOptions);
    }
  }

  showSessionMessage(messages: any) {
    let msg = '';
    if (_.isArray(messages)) {
      _.forEach(messages, (message, key) => {
        msg += message + '<br>';
      });
    } else {
      msg = this.translateService.instant(messages);
    }

    if (msg !== '') {
      const message = msg;
      const help =
        'For any assistance write to contact@ohmobility.com or call +91-8867037812';
      const options = {
        ...this.toastOptions,
        positionClass: 'toast-top-center',
      };
      this.toastyService.error(message, 'Session Expired', options);
      this.toastyService.info(help, 'Info', options);
    }
  }

  showSuccess(messages: any) {
    let msg = '';
    if (_.isArray(messages)) {
      _.forEach(messages, (message, key) => {
        msg += message + '<br>';
      });
    } else {
      msg = this.translateService.instant(messages);
    }

    if (msg !== '') {
      this.toastyService.success(msg, 'Success', this.toastOptions);
    }
  }

  showInfo(message: string, title: string = 'Info'): void {
    this.toastyService.info(message, title, this.toastOptions);
  }

  showError(message: string) {
    this.show(ToastTypes.Error, message);
  }
}
